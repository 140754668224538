import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../styles/Header.css';
import UserMenu from './UserMenu';

function Header() {
  const { currentUser } = useAuth();

  return (
    <header className="Header">
      <div className="Header-content">
        <div className="Header-left">
          <img src="/logo_white.png" alt="BIM Analytics Logo" className="Header-logo" />
        </div>
        <div className="Header-right">
          <nav>
            {currentUser ? (
              <UserMenu />
            ) : (
              <Link to="/login" className="Login-link">Login</Link>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;