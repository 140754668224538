import React, { createContext, useContext, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  onAuthStateChanged, 
  signInWithEmailAndPassword, 
  signOut,
  getIdToken
} from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "AIzaSyC7tSAXEa8wX3KTqztIJLr74H41vy7zGV8",
//   authDomain: "staging-site-432914.firebaseapp.com",
//   projectId: "staging-site-432914",
//   storageBucket: "staging-site-432914.appspot.com",
//   messagingSenderId: "301040375636",
//   appId: "1:301040375636:web:13fcc6b903b0f364781ac1"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDvsvTLs68j2QO4Nd8xn0iWYzledywTw-w",
  authDomain: "bim-main-site.firebaseapp.com",
  projectId: "bim-main-site",
  storageBucket: "bim-main-site.appspot.com",
  messagingSenderId: "690220873571",
  appId: "1:690220873571:web:5fb94c23e306e3fbd64556",
  measurementId: "G-VHVYHW8VQD"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userClaims, setUserClaims] = useState(null);
  const [loading, setLoading] = useState(true);

  async function login(email, password) {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const token = await getIdToken(userCredential.user);
    return token;
  }

  function logout() {
    return signOut(auth);
  }

  async function getAuthToken() {
    if (currentUser) {
      return await getIdToken(currentUser, true);
    }
    return null;
  }

  function navigateToPlatform(platform) {
    getAuthToken().then(async (idToken) => {
      if (idToken) {
        try {
          const response = await fetch('https://europe-west2-bim-main-site.cloudfunctions.net/get_custom_tokens', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idToken })
          });
          const data = await response.json();
          const mainCustomToken = data.mainCustomToken;
          const platformCustomToken = data.platformCustomToken;
          console.log(data);

          if (!mainCustomToken || !platformCustomToken) {
            console.error('Failed to get custom tokens');
            return;
          }

          const platformWindow = window.open(`https://insurance.bimanalytics.ai`, '_blank');

          const message = { mainCustomToken, platformCustomToken };
          const sendMessage = () => {
            // platformWindow.postMessage(message, `https://staging.${platform}.bimanalytics.ai`);
            platformWindow.postMessage(message, `https://insurance.bimanalytics.ai`);
          };

          platformWindow.onload = sendMessage;
          setTimeout(sendMessage, 1000);

        } catch (error) {
          console.error('Error navigating to platform:', error);
        }
      } else {
        console.error('User not authenticated');
      }
    });
  }

  function checkPermission(permission) {
    return userClaims && userClaims.permissions && userClaims.permissions.includes(permission);
  }

  function checkRole(role) {
    return userClaims && userClaims.role === role;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        setUserClaims(idTokenResult.claims);
      } else {
        setUserClaims(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userClaims,
    login,
    logout,
    getAuthToken,
    navigateToPlatform,
    checkPermission,
    checkRole
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}