import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Button, Box, Typography } from '@mui/material';

function PlatformLinks() {
  const { userClaims, checkPermission, navigateToPlatform } = useAuth();

  const hasInsuranceAccess = checkPermission('insurance');
  const hasIntelligenceAccess = checkPermission('intelligence');

  return (
    <Box className="PlatformLinks" sx={{ padding: 2, backgroundColor: 'background.paper' }}>
      <Typography variant="h2" gutterBottom>Our Platforms</Typography>
      <Box className="platform-buttons" sx={{ display: 'flex', gap: 2 }}>
        <Button
          onClick={() => navigateToPlatform('insurance')}
          disabled={!hasInsuranceAccess}
          variant="contained"
          color={hasInsuranceAccess ? 'primary' : 'secondary'}
        >
          Insurance Platform
        </Button>
        <Button
          onClick={() => navigateToPlatform('intelligence')}
          disabled={!hasIntelligenceAccess}
          variant="contained"
          color={hasIntelligenceAccess ? 'primary' : 'secondary'}
        >
          Intelligence Platform
        </Button>
      </Box>
    </Box>
  );
}

export default PlatformLinks;