import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Box, Button, TextField, Typography, Paper, Grid } from '@mui/material';
import Header from '../components/Header';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    name: '',
    organisation: '',
    role: 'user',
    permissions: []
  });
  const { getAuthToken, checkRole } = useAuth();
  const isGod = checkRole('god');
  const isAdmin = checkRole('admin');

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    try {
      const token = await getAuthToken();
      const response = await fetch('https://europe-west2-bim-main-site.cloudfunctions.net/get_users', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setUsers(data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  async function handleAddUser(e) {
    e.preventDefault();
    try {
      const token = await getAuthToken();
      const response = await fetch('https://europe-west2-bim-main-site.cloudfunctions.net/create_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newUser)
      });
      const data = await response.json();
      if (data.success) {
        fetchUsers();
        setNewUser({
          email: '',
          password: '',
          name: '',
          organisation: '',
          role: 'user',
          permissions: []
        });
      } else {
        console.error('Error creating user:', data.error);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  async function handleEditUser(userId, updatedClaims) {
    try {
      const token = await getAuthToken();
      const response = await fetch('https://europe-west2-bim-main-site.cloudfunctions.net/edit_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ user_id: userId, claims: updatedClaims })
      });
      const data = await response.json();
      if (data.success) {
        fetchUsers();
      } else {
        console.error('Error editing user:', data.error);
      }
    } catch (error) {
      console.error('Error editing user:', error);
    }
  }

  async function handleDeleteUser(userId) {
    try {
      const token = await getAuthToken();
      const response = await fetch('https://europe-west2-bim-main-site.cloudfunctions.net/delete_user', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ user_id: userId })
      });
      const data = await response.json();
      if (data.success) {
        fetchUsers();
      } else {
        console.error('Error deleting user:', data.error);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  }

  return (
    <div className="UserManagement">
      <Header />
      <Box sx={{ padding: 2, backgroundColor: 'background.paper' }}>
        <Typography variant="h1" gutterBottom>User Management</Typography>
        <Typography variant="h2" gutterBottom>All Users</Typography>
        <Grid container spacing={2}>
          {users.map(user => (
            <Grid item xs={12} key={user.user_id}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="body1">{user.name} - {user.email} - {user.organisation} - {user.role}</Typography>
                {(isGod || (isAdmin && user.role === 'user')) && (
                  <>
                    <Button onClick={() => handleEditUser(user.user_id, { /* updated claims */ })} variant="contained" color="primary" sx={{ marginRight: 1 }}>Edit</Button>
                    <Button onClick={() => handleDeleteUser(user.user_id)} variant="contained" color="secondary">Delete</Button>
                  </>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
        {(isGod || isAdmin) && (
          <>
            <Typography variant="h2" gutterBottom>Add New User</Typography>
            <form onSubmit={handleAddUser}>
              <TextField
                type="email"
                label="Email"
                value={newUser.email}
                onChange={e => setNewUser({...newUser, email: e.target.value})}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                type="password"
                label="Password"
                value={newUser.password}
                onChange={e => setNewUser({...newUser, password: e.target.value})}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="Name"
                value={newUser.name}
                onChange={e => setNewUser({...newUser, name: e.target.value})}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="Organisation"
                value={newUser.organisation}
                onChange={e => setNewUser({...newUser, organisation: e.target.value})}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                select
                label="Role"
                value={newUser.role}
                onChange={e => setNewUser({...newUser, role: e.target.value})}
                required
                fullWidth
                margin="normal"
                SelectProps={{
                  native: true,
                }}
              >
                <option value="user">User</option>
                {isGod && <option value="admin">Admin</option>}
              </TextField>
              <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
                <label>
                  <input
                    type="checkbox"
                    checked={newUser.permissions.includes('insurance')}
                    onChange={e => {
                      const newPermissions = e.target.checked
                        ? [...newUser.permissions, 'insurance']
                        : newUser.permissions.filter(p => p !== 'insurance');
                      setNewUser({...newUser, permissions: newPermissions});
                    }}
                  />
                  Insurance
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={newUser.permissions.includes('intelligence')}
                    onChange={e => {
                      const newPermissions = e.target.checked
                        ? [...newUser.permissions, 'intelligence']
                        : newUser.permissions.filter(p => p !== 'intelligence');
                      setNewUser({...newUser, permissions: newPermissions});
                    }}
                  />
                  Intelligence
                </label>
              </Box>
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
                Add User
              </Button>
            </form>
          </>
        )}
      </Box>
    </div>
  );
}

export default UserManagement;