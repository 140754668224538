import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function PrivateRoute({ children, requiredPermission, requiredRoles }) {
  const { currentUser, checkPermission, checkRole } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (requiredPermission && !checkPermission(requiredPermission)) {
    return <Navigate to="/" />;
  }
  if (requiredRoles && !requiredRoles.some(role => checkRole(role))) {
    return <Navigate to="/" />;
  }

  return children;
}

export default PrivateRoute;