import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button, TextField, Box, Typography, Container } from '@mui/material';
import Header from '../components/Header';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError('');
      await login(email, password);
      navigate('/');
    } catch (error) {
      setError('Failed to log in');
      console.error(error);
    }
  };

  return (
    <div className="LoginPage">
      <Header />
      <main>
        <Container maxWidth="sm">
          <Box 
            sx={{ 
              padding: 2, 
              backgroundColor: 'background.paper',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '100px'
            }}
          >
            <Typography variant="h2" gutterBottom>Login</Typography>
            {error && <Typography color="error">{error}</Typography>}
            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%'}}>
              <TextField
                type="email"
                id="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                margin="normal"
                maxWidth="sm"
              />
              <TextField
                type="password"
                id="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                fullWidth
                margin="normal"
                maxWidth="sm"
              />
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                sx={{ mt: 2, width: '50%' }}
              >
                Log In
              </Button>
            </Box>
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default LoginPage;