import React from 'react';
import { Box, Typography } from '@mui/material';
import Header from '../components/Header';
import PlatformLinks from '../components/PlatformLinks';

function LandingPage() {
  return (
    <div className="LandingPage">
      <Header />
      <main>
        <Box sx={{ 
          padding: 2, 
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Typography variant="h1" gutterBottom>Welcome to BIM Analytics</Typography>
          <Typography variant="body1" gutterBottom>
            Empowering businesses with AI-driven analytics and insurance solutions
          </Typography>
          <PlatformLinks />
        </Box>
      </main>
    </div>
  );
}

export default LandingPage;